import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import css from './dropzone.module.css'
import {Button} from 'react-bootstrap';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';

//----------------------------------------------------
export function MyDropzone() {
    const [crt, set_crt] = useState({});
    const [pem, set_pem] = useState({});
    const [key, set_key] = useState({});
    const [ca, set_ca] = useState({});
    const [ovpn, set_ovpn] = useState({});
    //----------------------------------------------
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')

            reader.onload = function (e) {
                const contents = e.target.result;
                //console.log('##||| ', contents)
                switch (file.name !== 'ca.crt' ? file.name.split('.').reverse()[0] : file.name) {
                    case 'ca.crt':
                        set_ca({name: file.name, contents});
                        break;
                    case 'pem':
                        set_pem({name: file.name, contents});
                        break;
                    case 'crt':
                        set_crt({name: file.name, contents});
                        break;
                    case 'key':
                        set_key({name: file.name, contents});
                        break;
                    case 'ovpn':
                        set_ovpn({name: file.name, contents});
                        break;
                    default:
                        break;
                }


            };
            reader.readAsText(file);
        })

    }, [])
    //-----------------------------------------------------------------------------------------
    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
        maxFiles: 6, maxSize: 1040000, accept: {
            'text/ovpn': ['.crt', '.pem', '.conf', '.key', '.ovpn']
        }, onDrop
    })
    //-------------------------------------------------------------------------------------
    /*const CancelIconClick = (file) => {
        //console.log('file', file)
        switch (file.name) {
            case 'ca':
                return <><span style={{color: '#fff'}}>{file.name}</span><CancelIcon className={css.close}
                                                                                     onClick={() => set_ca({})}/></>

            case 'pem':
                return <CancelIcon className={css.close} onClick={() => set_pem({})}/>

            case 'crt':
                return <CancelIcon className={css.close} onClick={() => set_crt({})}/>

            case 'key':
                return <CancelIcon className={css.close} onClick={() => set_key({})}/>

            case 'ovpn':
                return <CancelIcon className={css.close} onClick={() => set_ovpn({})}/>

            default:
                return <></>

        }

    }*/
    //-------------------------------------------------------------------------------------
    const acceptedFileItems = acceptedFiles.map(file => {


        return <li key={file.path}>
            {/*<CancelIconClick name={file.name.split('.').reverse()[0]} />*/}
            <span style={{
                color: '#6bb5ff', float: 'left'
            }}>{file.name.split('.').reverse()[0]} -
                <span style={{color: 'yellowgreen'}}> {file.path}</span>
            </span>

        </li>
    });

    //---------------------------------------------------------------------------------
    const fileRejectionItems = fileRejections.map(({file, errors}) => (<li
        style={{float: 'left'}}
        key={file.path}>
        {file.path}
        <ul>
            {errors.map(e => (<li key={e.code}>{e.message}</li>))}
        </ul>
    </li>));
    //----------------------------------------------------------------------------------
    const CreateFile = (nameFile) => {

        let mod_file = ovpn.contents.split('ca ca.crt')[0];
        // ca ca.crt
        mod_file += `<ca>\r\n` + ca.contents + `</ca>\r\n`
        // dh dh2048.pem
        mod_file += `<dh>\r\n` + pem.contents + `</dh>\r\n`
        // cert
        mod_file += `<cert>\r\n` + crt.contents + `</cert>\r\n`
        // key
        mod_file += `<key>\r\n` + key.contents + `</key>\r\n`
        // end
        mod_file += 'remote' + ovpn.contents.split('remote')[1];
        //------------------------------------------------------

        let blob = new Blob([mod_file], {type: 'text/plain'});
        let link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        URL.createObjectURL(blob);
        link.setAttribute('download', 'mod_' + nameFile)
        link.click();
    }
    /////////////////////////////////////////////////////////////////////////////////////
    //----------------------------------------------------------------------------------
    const CreateFile_no_dh = (nameFile) => {

        let mod_file = ovpn.contents.split('ca ca.crt')[0];
        // ca ca.crt
        mod_file += `<ca>\r\n` + ca.contents + `</ca>\r\n`
        // dh dh2048.pem
        //mod_file += `<dh>\r\n` + pem.contents + `</dh>\r\n`
        // cert
        mod_file += `<cert>\r\n` + crt.contents + `</cert>\r\n`
        // key
        mod_file += `<key>\r\n` + key.contents + `</key>\r\n`
        // end
        mod_file += 'remote' + ovpn.contents.split('remote')[1];
        //------------------------------------------------------

        let blob = new Blob([mod_file], {type: 'text/plain'});
        let link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        URL.createObjectURL(blob);
        link.setAttribute('download', 'mod_(no_dh)_' + nameFile)
        link.click();
    }
    /////////////////////////////////////////////////////////////////////////////////////

    return (<>
        <h1 className={css.Title}>Модифікувати конфігурацію OpenVPN<p>( Mac, iPhone, Android, ... )</p></h1>
        <section>
            <div style={{
                border: '1px dashed #8494a7', padding: '50px', cursor: 'pointer',
            }}>

                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>Перетягніть файли конфігурації сюди або натисніть, щоб вибрати файли</p>
                    <em>(Приймаються лише *.crt, *.pem, *.key, *.ovpn)</em>
                </div>
            </div>


            <aside className={css.Accepted}>
                <h4 style={{color: '#fce48c', marginTop: '10px', fontSize: '1em'}}>Вибрані файли:</h4>
                <ul>{acceptedFileItems}</ul>

            </aside>
            <div className={css.caseButton}>

                {crt.contents && pem.contents && key.contents && ca.contents && ovpn.contents ? (<div>
                    <Button className={css.formUserButton}
                            variant="outline-secondary" type="submit"
                            onClick={() => {
                                CreateFile(ovpn.name)
                            }}>
                        <DownloadIcon/>1. mod_*.ovpn "dh"
                    </Button>


                </div>) : <></>}

                {crt.contents && key.contents && ca.contents && ovpn.contents ? (<div>
                    <Button className={css.formUserButton}
                            variant="outline-secondary" type="submit"
                            onClick={() => {
                                CreateFile_no_dh(ovpn.name)
                            }}>
                        <DownloadIcon/>2. mod_*.ovpn <span style={{
                        textDecoration: 'line-through', color: '#cc8449'
                    }}>"dh"</span>
                    </Button>


                </div>) : <></>}
            </div>
            <div className={css.State}>
                {ca.name && <div>{ca.name} <CancelIcon className={css.close}
                                                       onClick={() => set_ca({})}/></div>}
                {pem.name && <div>{pem.name} <CancelIcon className={css.close}
                                                         onClick={() => set_pem({})}/></div>}
                {crt.name && <div>{crt.name} <CancelIcon className={css.close}
                                                         onClick={() => set_crt({})}/></div>}
                {key.name && <div>{key.name} <CancelIcon className={css.close}
                                                         onClick={() => set_key({})}/></div>}
                {ovpn.name && <div>{ovpn.name} <CancelIcon className={css.close}
                                                           onClick={() => set_ovpn({})}/></div>}
            </div>
            <aside className={css.Reject}>
                <ul>{fileRejectionItems}</ul>
            </aside>
        </section>

    </>)
}
